<template>
  <div id="knowledge-base-article">
    <div class="vx-row">
      <div class="vx-col w-full md:w-2/5 lg:w-1/4">
        <vx-card title="Related Questions">
          <ul class="bordered-items">
            <li
              v-for="que in selectedQuestion.relatedQuestions"
              :key="que.id"
              class="py-2">
              <a
                href="#"
                @click.prevent="changeRelatedQuestion(que)">{{ que.title }}</a>
            </li>
          </ul>
        </vx-card>
      </div>
      <div class="vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0">
        <vx-card>
          <!-- Title -->
          <div class="article-title mb-6">
            <h1>{{ selectedQuestion.title }}</h1>
<!--            <p class="mt-1">Last updated on <span>{{ article.lastUpdated | date(true) }}</span></p>-->
          </div>

          <!-- Content -->
          <p v-if="selectedQuestion.textBeforeImage">{{ selectedQuestion.textBeforeImage }}</p>

          <img :src="selectedQuestion.img" alt="article-img" class="responsive my-3 rounded" />

          <p
            v-if="selectedQuestion.textBeforeTopics"
            class="mb-6">{{ selectedQuestion.textBeforeTopics }}</p>

          <!-- Topics -->
          <template v-if="selectedQuestion.topics.length > 0">
            <h5 class="mb-2">Topics:</h5>
            <vx-list :list="selectedQuestion.topics"></vx-list>
          </template>

          <p
            v-if="selectedQuestion.textAfterTopics"
            class="mt-4">{{ selectedQuestion.textAfterTopics }}</p>

          <!-- Footer -->
          <template slot="footer">
            <div class="flex items-center justify-between">
              <a
                href="#"
                class="flex items-center cursor-pointer"
                @click.prevent="prevArticle()">
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronsRightIcon' : 'ChevronsLeftIcon'"
                  class="mr-2 cursor-pointer"
                  svgClasses="h-4 w-4"/>
                {{ $t('NextArticle') }}
              </a>
              <a
                href="#"
                class="flex items-center cursor-pointer"
                @click.prevent="nextArticle()">
                {{ $t('PrevArticle') }}
                <feather-icon
                  :icon="$vs.rtl ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'"
                  class="ml-2 cursor-pointer"
                  svgClasses="h-4 w-4"/>
              </a>
            </div>
          </template>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';

export default {
  i18n: {
    messages: {
      en: {
        PrevArticle: 'Previous Article',
        NextArticle: 'Next Article',
      },
    },
  },
  computed: {
    ...mapState({
      selectedCategory: (state) => state.documentation.selectedCategory,
      selectedSection: (state) => state.documentation.selectedSection,
      selectedQuestion: (state) => state.documentation.selectedQuestion,
    }),
  },
  mounted() {
    // this.$emit('changeRouteTitle', 'Question');
    if (!this.selectedCategory || !this.selectedSection || !this.selectedQuestion) {
      this.$router.push('/documentation').catch(() => {});
    } else {
      this.updateLastBreadcrumbItemTitle(this.selectedQuestion.title);
    }
  },
  methods: {
    ...mapActions({
      updateLastBreadcrumbItemTitle: 'updateLastBreadcrumbItemTitle',
      setSelectedQuestionByIds: 'documentation/setSelectedQuestionByIds',
      prevQuestion: 'documentation/prevQuestion',
      nextQuestion: 'documentation/nextQuestion',
    }),
    prevArticle() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.prevQuestion();
    },
    nextArticle() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.nextQuestion();
    },
    changeRelatedQuestion(question) {
      this.setSelectedQuestionByIds({
        categoryId: question.categoryId,
        sectionId: question.sectionId,
        questionId: question.questionId,
      });
      this.$router.push(question.url).catch(() => {});
    },
  },
};
</script>
